import "./styles/reset.scss"
import "./styles/global.scss"
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import LoginPage from "./components/login/LoginPage"
import RootPage from "./components/login/RootPage"
import EchoboxHome from "./components/echobox/EchoboxHome"
import FeedPage from "./components/echobox/feed/FeedPage"
import AnalyticsPage from "./components/echobox/analytics/AnalyticsPage"
import { default as EchoBoxConfigPage } from "./components/echobox/config/ConfigPage"
import AutoCommentsHome from "./components/autoComments/AutoCommentsHome"
import { default as AutoCommentConfigPage } from "./components/autoComments/ConfigPage"
import { default as FeederConfigPage } from "./components/feeder/config/ConfigPage"
import PrivateRoute from "./context/PrivateRoute"
import HomePage from "./components/login/HomePage"
import FacebookConnect from "./components/facebook/FacebookConnect"
import FeederHome from "./components/feeder/FeederHome"
import QueuePage from "./components/feeder/queue/QueuePage"
import SchedulerPage from "./components/feeder/scheduler/SchedulerPage"

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "/",
        element: <RootPage />,
        children: [
          {
            path: "/",
            element: <HomePage />,
            // element: <Navigate to="echobox/:toolPageId" replace />,
            // need to update the default page @toolPage
          },
          {
            path: "echobox/:toolPageId",
            element: <EchoboxHome />,
            children: [
              { index: true, element: <Navigate to="feed" replace /> },
              { path: "feed", element: <FeedPage /> },
              { path: "analytics", element: <AnalyticsPage /> },
              { path: "config", element: <EchoBoxConfigPage /> },
            ],
          },
          {
            path: "autocomments/:toolPageId",
            element: <AutoCommentsHome />,
            children: [
              { index: true, element: <Navigate to="config" replace /> },
              { path: "config", element: <AutoCommentConfigPage /> },
            ],
          },
          {
            path: "feeder/:toolPageId",
            element: <FeederHome />,
            children: [
              { index: true, element: <Navigate to="pending" replace /> },
              { path: "pending", element: <QueuePage /> },
              { path: "scheduler", element: <SchedulerPage /> },
              { path: "config", element: <FeederConfigPage /> },
            ],
          },
          {
            path: "facebook/connect",
            element: <FacebookConnect />,
          },
        ],
      },
    ],
  },
])

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
