import styles from "./QueueChildPost.module.scss"

const QueueChildPost = ({ displayChildTable, originalPostContent }) => {
  return (
    <tr className={`table_content ${styles.child_row}`}>
      <td
        colSpan={3}
        className={`${styles.child_cell} ${!displayChildTable && styles.hide}`}
      >
        <div className={`${styles.child_content}`}>
          <h2>Original content</h2>
          <p>{originalPostContent}</p>
        </div>
      </td>
    </tr>
  )
}

export default QueueChildPost
