import styles from "./NavList.module.scss"
import { NavLink, useLocation } from "react-router-dom"
import { useState } from "react"
import { IoAddCircleOutline } from "react-icons/io5"
import { BiError } from "react-icons/bi"
import usePage from "../../context/usePage"
import { acb } from "../../lib/axios"

function getDefaultOpt(currentUrl) {
  if (currentUrl.match(/^\/echobox\//)) {
    return "echobox"
  } else if (currentUrl.match(/^\/autocomments\//)) {
    return "autocomments"
  } else if (currentUrl.match(/^\/feeder\//)) {
    return "feeder"
  }
  return "echobox"
}

async function startFacebookAuthorization() {
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken

  const response = await acb.get("user/token/add/start", {
    headers: {
      "Session-Token": token,
    },
  })

  const redirect_uri = encodeURIComponent(
    `${window.location.protocol}://${window.location.hostname}/facebook/connect`
  )

  if (response.status === 200) {
    const authUrl = response.data.data.authURL
      .replace("scope=", "scope=business_management,")
      .replace(/redirect_uri=[^&]+/, `redirect_uri=${redirect_uri}`)

    window.location.href = authUrl
  }
}

const NavList = (props) => {
  const { allPages } = usePage()
  const currentLocation = useLocation()
  const currentToolPath = getDefaultOpt(currentLocation.pathname)
  const [selectOpt, setselectOpt] = useState(currentToolPath)

  const changeToolHandler = (event) => {
    setselectOpt(event.target.value)
  }

  return (
    <div className={`overlay ${props.status ? styles.show : null}`}>
      <div className={`${styles.wrapper} ${props.status ? styles.show : null}`}>
        <nav className={styles.nav_menu}>
          <div className={styles.options}>
            <button
              value="echobox"
              onClick={changeToolHandler}
              className={`${selectOpt === "echobox" ? styles.selected : ""}`}
            >
              EchoBox
              <span
                className={`${
                  currentToolPath === "echobox"
                    ? styles.active
                    : styles.inactive
                }`}
              >
                •
              </span>
            </button>
            <button
              value="autocomments"
              onClick={changeToolHandler}
              className={`${
                selectOpt === "autocomments" ? styles.selected : ""
              }`}
            >
              Auto comments
              <span
                className={`${
                  currentToolPath === "autocomments"
                    ? styles.active
                    : styles.inactive
                }`}
              >
                •
              </span>
            </button>
            <button
              value="feeder"
              onClick={changeToolHandler}
              className={`${selectOpt === "feeder" ? styles.selected : ""}`}
            >
              Feeder
              <span
                className={`${
                  currentToolPath === "feeder" ? styles.active : styles.inactive
                }`}
              >
                •
              </span>
            </button>
          </div>
          <ul className={styles.page_list}>
            {allPages.length > 0 ? (
              allPages.map((page) => (
                <li key={page.id} className={styles.page}>
                  <NavLink
                    to={`${selectOpt}/${page.id}`}
                    className={({ isActive }) =>
                      isActive ? styles.active : undefined
                    }
                    onClick={() => props.onNavClose(page)}
                  >
                    <img src={page.logo} alt={page.name} />
                    <span className={styles.pagetitle}>{page.name}</span>
                  </NavLink>
                </li>
              ))
            ) : (
              <div>
                <BiError className={styles.page_icon} />
                <span>{props.errMsg}</span>
              </div>
            )}
          </ul>
          <div className={styles.add_page}>
            <button
              onClick={() => startFacebookAuthorization()}
              className="primary_outline_btn"
            >
              <span>Add/Remove Page</span>
              <IoAddCircleOutline size={22} />
            </button>
          </div>
        </nav>
      </div>
      <div
        className={styles.close_area}
        onClick={() => props.onNavClose()}
      ></div>
    </div>
  )
}

export default NavList
