import { useEffect, useState } from "react"
import QueueList from "./QueueList"
import { api } from "../../../lib/axios"
import usePage from "../../../context/usePage"
import LoadingSpinner from "../../utilities/LoadingSpinner"

const QueuePage = () => {
  const [queueData, setQueueData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()

  useEffect(() => {
    const fetchQueueData = async () => {
      setIsLoading(true)
      // TODO: Should fetch a specific page
      try {
        const response = await api.get("/rewritten-posts/", {
          params: {
            relationships__child_page_id: selectedPage.id,
            status: "pending",
          },
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data
        setQueueData(responseData)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchQueueData()
  }, [selectedPage, token])

  const save = async (change) => {
    const { postid, action, postContent, selectedImgId } = change
    try {
      const response = await api.patch(
        `/rewritten-posts/${postid}/`,
        {
          status: action,
          content: postContent,
          choosen_image: selectedImgId,
        },
        {
          headers: {
            "Session-Token": token,
          },
        }
      )
      if (response.status === 200) {
        setQueueData((pre) => pre.filter((item) => item.id !== postid))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const postActionHandler = (postid, action, postContent, selectedImgId) => {
    save({
      postid,
      action,
      postContent,
      selectedImgId,
    })
  }

  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <QueueList posts={queueData} onPostAction={postActionHandler} />
      )}
    </section>
  )
}

export default QueuePage
