import { Outlet } from "react-router-dom"
import Breadcrumb from "../utilities/Breadcrumb"
import PageTitle from "../utilities/PageTitle"

const FeederHome = () => {
  const FeederHeader = {
    ToolName: "Feeder",
    navTag: ["Pending", "Scheduler", "Config"],
  }

  return (
    <>
      <PageTitle />
      <Breadcrumb info={FeederHeader} />
      <Outlet />
    </>
  )
}

export default FeederHome
